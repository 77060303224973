import {EffectFade, Navigation, Swiper, Pagination, Thumbs} from "swiper";

$(function () {
    new Swiper('.uq-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 20,
        speed: 500,
        grabCursor: true,
        breakpoints: {
            375: {
                slidesPerView: 1.2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 1.8,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 2.2,
                spaceBetween: 20
            }
        }
    });

    new Swiper('#related-product-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        spaceBetween: 56,
        slidesPerView: 3,
        speed: 500,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 16
            },
            768: {
                slidesPerView: 2.45,
                spaceBetween: 24
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 56
            }
        }
    });

    new Swiper('#partner-slider', {
        modules: [Navigation, EffectFade],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        effect: "fade",
        sliderPerView: 1,
        spaceBetween: 0,
        speed: 500,
        grabCursor: true,
    });

    // Hero slider thumbs
    const heroSwiperThumbs = new Swiper("#hero-thumbs", {
        spaceBetween: 0,
        slidesPerView: 6,
        watchSlidesProgress: true,
    });

    // Hero slider main
    const heroSwiper = new Swiper("#header-slider", {
        modules: [Navigation, Thumbs, Pagination],
        spaceBetween: 0,
        watchSlidesProgress: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
        },
        thumbs: {
            swiper: heroSwiperThumbs,
        },
    });


    // Product slider thumbs
    const swiperProduct = new Swiper(".product-bottom", {
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 0,
        slidesPerView: 4,
        watchOverflow: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        direction: 'vertical',
    });

    // Product slider main
    const swiperProductMain = new Swiper("#product-slider", {
        modules: [Thumbs, EffectFade],
        spaceBetween: 0,
        watchOverflow: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        preventInteractionOnTransition: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        thumbs: {
            swiper: swiperProduct,
        },
    });

    const clientOpinionThumb = new Swiper('#client-opinion', {
        modules: [Navigation, EffectFade],
        effect: "fade",
        sliderPerView: 1,
        spaceBetween: 0,
        speed: 500,
        grabCursor: true,
    });

    const clientImageSlider = new Swiper('#client-image', {
        modules: [Thumbs, Navigation, EffectFade],
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        sliderPerView: 1,
        spaceBetween: 0,
        speed: 500,
        navigation: {
            nextEl: ".clients-button-next",
            prevEl: ".clients-button-prev",
        },
        thumbs: {
            swiper: clientOpinionThumb,
        },
    });

    if (!!clientOpinionThumb) {
        clientOpinionThumb.on('slideChange', function () {
            clientImageSlider.slideTo(clientOpinionThumb.activeIndex);
        });
    }
});
