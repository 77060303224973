$(document).ready(function () {
    $('.uq-ajax-form').each(function () {
        let $element = $(this);

        let reloadGrid = function (href) {
            // Cancel previous request
            if (window.source !== undefined) {
                // Cancel previous request
                window.source.cancel();
            }

            //Container of items posts
            let $grid = $('#filters-container .posts-section');

            window.source = window.axios.CancelToken.source();

            window.axios.get(href, {cancelToken: source.token})
                .then(function (response) {
                    //Every child item
                    let $items = $grid.find('.post-element');

                    //Remove old items
                    $items.remove();

                    let $data = $(response.data);

                    let timer = 300;
                    //Add new filtered elements
                    $data.find('.post-element')
                        .each(function () {
                            let $this = $(this);

                            $this.appendTo($grid).hide().fadeIn();
                        });

                    window.source = undefined;
                }).catch(() => { /*Silence is good*/
            });
        }

        // Prevent close on click in
        let $filter_buttons = $('.uq-filters .dropdown-item .btn-link');

        $filter_buttons.each(function () {
            $(this).on('click', function (e) {
                e.stopPropagation();
            })
        });

        $element.on('filter.applied filter.clean.all', function () {
            let href = $element.attr('action');

            let filters = [];

            $element.find('[data-type="selected-container"] [data-filter],[data-type="selected-container"] [data-search]')
                .each(function () {
                    let $this = $(this);

                    let filter = {
                        name: $this.data('filter') ? $this.data('filter') : 'q',
                        value: $this.data('value'),
                    };

                    filters.push(filter);
                });

            let data = {};

            $(filters).each(function (index, obj) {
                if (data[obj.name] === undefined) {
                    if (obj.name === ['active', 'q'].includes(obj.name)) {
                        data[obj.name] = obj.value;
                    } else {
                        data[obj.name] = [obj.value];
                    }
                } else {
                    data[obj.name].push(obj.value);
                }
            });

            try {
                function encodeQueryData(data) {
                    const ret = [];

                    for (let d in data) {
                        let value = data[d];

                        if (Array.isArray(value)) {
                            for (let i in value) {
                                ret.push(encodeURIComponent(d) + '[]=' + encodeURIComponent(value[i]));
                            }
                        } else {
                            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
                        }
                    }

                    return ret.join('&');
                }

                let filters = encodeQueryData(data);

                let href_query = href + (filters ? '?' + filters : '');

                window.history.pushState({href: href_query}, '', href_query);

                reloadGrid(document.location);
            } catch (e) {
            }
        });

        /**
         * Apply filter
         *
         * @param e
         */
        let fn = function (e) {
            let $filter = $(e.currentTarget);

            let $form = $filter.closest('.uq-ajax-form');
            let $filterList = $form.closest('.uq-ajax-form').find('[data-type="selected"]');
            let $filterListContainer = $filterList.find('[data-type="selected-container"]');

            let filter_data = '[data-filter="' + $filter.data('filter') + '"][data-value="' + $filter.data('value') + '"]';

            // List of applied
            let $filterApplied = $filterListContainer.find(filter_data);

            if ($filterApplied.length > 0) {
                $filterApplied.remove();
            } else {
                let $filter_clone = $filter.clone();

                $filter_clone.on('click', fn);
                $filterListContainer.append($filter_clone);
            }

            //
            if (!$form.find(filter_data).hasClass('active')) {
                $form.find(filter_data).addClass('active');
            } else {
                $form.find(filter_data).removeClass('active');
            }

            // Check if container is empty
            if (!$filterListContainer.html().trim()) {
                if (!$filterList.hasClass('d-none')) {
                    $filterList.addClass('d-none')
                }
            } else if ($filterList.hasClass('d-none')) {
                $filterList.removeClass('d-none')
            }

            if (['click', 'input'].includes(e.type)) {
                $form.trigger('filter.applied');
            }
        };

        $element.find('[data-filter]').on('click filter.click', function (e) {
            fn(e);
        });

        $element.find('[data-search]').on('input input.search', function (e) {
            fn(e);
        });

        /**
         * Clean all filters applied
         */
        $element.find('[data-type="clear-all"]').on('click', function () {
            let $this = $(this);
            let $form = $this.closest('.uq-ajax-form');

            let $filterList = $form.find('[data-type="selected"]');
            let $filterListContainer = $filterList.find('[data-type="selected-container"]');

            $filterListContainer.find('[data-filter].active,[data-search]').remove();
            $form.find('[data-filter].active').removeClass('active');
            $form.find('[data-search]').val('')

            if (!$filterListContainer.html().trim()) {
                if (!$filterList.hasClass('d-none')) {
                    $filterList.addClass('d-none')
                }
            } else if ($filterList.hasClass('d-none')) {
                $filterList.removeClass('d-none')
            }

            $form.trigger('filter.clean.all');
        });

        /**
         * Apply filters when browser was reloaded
         * or direct access from link
         *
         * @param location
         */
        let loadFilters = function (location) {
            if (location === undefined) {
                location = window.location;
            }

            const params = new URLSearchParams(location.search)

            for (let param of params) {
                let name;

                let index = param[0].indexOf('[');

                if (index !== -1) {
                    name = param[0].slice(0, index);
                } else if (index === -1) {
                    name = param[0];
                }

                let value = param[1];

                if (!['q', 'a'].includes('name')) {
                    $element.find('[data-filter="' + name + '"][data-value="' + value + '"]:not(.active)')
                        .trigger('filter.click');
                } else {
                    $element.find('[data-search]').trigger('input.search');
                }
            }
        }

        loadFilters();

        // Prevent submit
        $('body').on('submit', '.uq-ajax-form', function (e) {
            e.preventDefault();
        });

        $(window).on('popstate', function () {
            reloadGrid(document.location);
            loadFilters(document.location);
        });

    });
});
