$(function () {
    const $search = $('.product-search-form');

    $search.append('<span class="search-loader"></span>')

    $search.on('submit', (function (e) {
        e.preventDefault();
        e.stopPropagation();
    }));
});
