$(document).ready(function () {
    const $html = $('html');
    const $body = $('body');
    const $header = $('[data-plugin="sticky"]');
    const $h_height = $('#top').innerHeight();
    const $page_header = $('.header-full-image')
    const $is_fixed = $header.hasClass('header-over-image');
    const $top_nav = $('#top-nav');

    //Hamburger
    let $button_close = $('[data-bs-toggle="collapse"].hamburger');

    if ($button_close.length > 0) {
        $button_close.on('click', function () {
            let $this = $(this);

            if (!$this.hasClass('is-active')) {
                $this.addClass('is-active');

                if ($(window).width() < 992) {
                    $top_nav.toggleClass('close');
                    $body.addClass('menu-open');
                    $html.addClass('menu-open');
                }
            } else {
                $this.removeClass('is-active');
                $body.removeClass('menu-open');
                $html.removeClass('menu-open');
                $top_nav.toggleClass('close');
            }
        });
    }

    if ($page_header.length > 0 && $is_fixed) {
        $header.addClass('add-contrast-links')
        $page_header.css('margin-top', -$h_height)
    }

    $('.navbar-nav .menu-item.menu-item-has-children.dropdown .dropdown-menu .menu-item-has-children.dropdown > .dropdown-toggle').on('click', function(e){
        e.stopPropagation();
    });
});
